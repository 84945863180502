<script lang="ts" setup>
import { getCurrentDivisionId } from '@shared/composables/useDivisions'
import type {
  LeaseVariationInactiveReason,
  ILeaseVariation,
  ILeaseVariationDocument,
} from '@register'
import { LeaseVariationKind, DocumentStatus } from '@register'
import { leaseStatusObject } from '~/constants/LeaseStatus'

// PROPS & EMITS
const props = defineProps<{ amendment: ILeaseVariationDocument }>()
defineEmits<{
  (event: 'create'): void
  (event: 'delete', variation: ILeaseVariation): void
}>()

// GLOBAL
const dayjs = useDayjs()
const divisionId = getCurrentDivisionId()
const leaseId = getCurrentLeaseId()
const { dateFormat: leaseDateFormat } = useCurrentLease()

// VARIATIONS
const variations = computed(() => props.amendment.variations ?? [])

// DOCUMENT
const isNotPublished = computed(
  () => props.amendment.document?.status === DocumentStatus.NOT_PUBLISHED,
)

// FORMATTERS
const formatInactiveReason = (inactiveReason: LeaseVariationInactiveReason) => {
  return title(localizeVariationInactiveReason(inactiveReason)).replace(
    'Variation',
    '',
  )
}
const formatStartDate = (startDate: string | null | undefined) => {
  return startDate ? dayjs(startDate).format(leaseDateFormat.value) : 'N/A'
}

// CREATE
const addAmendmentButton = computed(() => {
  return props.amendment.document
    ? 'Add an amendment to this document'
    : 'Add an amendment without a document'
})

// EDIT
const createEditRoute = (variationId: string) =>
  `/division/${divisionId}/lease/${leaseId}/amendments/${variationId}`

function generateDynamicText(array: any) {
  if (!Array.isArray(array)) return array
  let text = ''

  array.forEach((entry: string, index: number) => {
    const entryText = Object.entries(entry)
      .map(([key, value]) => `${title(key)}: ${value}`)
      .join(', ')

    text += entryText

    // Add a comma if it's not the last entry
    if (index !== array.length - 1) {
      text += '\n'
    }
  })

  return text
}

const typeColor = (variationKind?: LeaseVariationKind | undefined) => {
  if (!variationKind) {
    return 'bg-white/10 text-gray-250'
  }

  if (variationKind === LeaseVariationKind.STATUS_CHANGED) {
    return 'text-primary'
  } else if (variationKind === LeaseVariationKind.REMOVED) {
    return 'text-danger'
  } else if (variationKind === LeaseVariationKind.CHANGED) {
    return 'text-orange'
  } else if (variationKind === LeaseVariationKind.ADDED) {
    return 'text-green-400'
  }
}

const statusClass = (variation: ILeaseVariation) => {
  const classes = [
    leaseStatusObject[variation.value as keyof typeof leaseStatusObject].color,
  ]

  if (variation.isCurrent && variation.isApproved)
    classes.push('font-semibold text-gray-200')

  if (!variation.isCurrent || !variation.isApproved) classes.push('opacity-40')

  return classes
}
</script>

<template>
  <div>
    <!-- Table header is on the file: AmendmentDocumentHeader.vue -->
    <div class="grid grid-cols-1">
      <div
        v-for="variation in variations"
        :key="variation.id"
        class="group/amendment bg-gray-750 hover:bg-gray-750 m-2 grid grid-cols-12 items-center gap-2 rounded-lg px-4 py-4 text-sm transition-colors md:m-0 md:rounded-none md:bg-inherit md:py-2"
        :class="{ 'text-gray-300': !variation.isCurrent }"
      >
        <!-- Effective date -->
        <div class="col-span-full md:col-span-1">
          <span class="text-xs text-gray-600 md:hidden">Created at:</span>
          <time>
            {{ formatStartDate(variation.createdAt) }}
          </time>
        </div>

        <!-- Effective date -->
        <div class="col-span-full md:col-span-1">
          <span class="text-xs text-gray-600 md:hidden">Effective:</span>
          <time>
            {{ formatStartDate(variation.startDate) }}
          </time>
        </div>

        <!-- Type -->
        <div class="order-first col-span-7 md:-order-none md:col-span-2">
          <span class="overflow-hidden rounded-lg bg-white/5 px-3 py-1 text-xs">
            {{ title(variation.type) }}:
            <span
              class="font-semibold"
              :class="typeColor(variation.variationKind)"
            >
              {{ title(variation.leaseField?.fieldType) }}
              {{ title(variation.variationKind) }}
            </span>
          </span>
        </div>

        <div class="col-span-full font-extrabold md:col-span-2">
          <span
            class="line-clamp-2"
            :class="{
              flex:
                variation?.leaseField?.name &&
                variation.leaseField.name.length > 20,
            }"
          >
            <span
              v-if="
                variation.variationKind === LeaseVariationKind.STATUS_CHANGED
              "
            >
              Lease status
            </span>
            <span v-else>
              {{ variation?.leaseField?.name ?? 'N/A' }}
            </span>
          </span>
        </div>

        <!-- Value -->
        <div class="col-span-full whitespace-pre md:col-span-4">
          <Tooltip
            :content="`Variation registered by ${variation.user.name ?? variation.user.email}`"
            class="bg-gray-950 text-xs"
            placement="top"
          >
            <span class="text-gray-550 line-clamp-5 whitespace-break-spaces">
              <span class="text-xs text-gray-600 md:hidden">New value:</span>
              <span
                class="flex"
                :class="{
                  'line-through': variation.inactiveReason === 'superseded',
                }"
              >
                <!-- Is current and approved -->
                <span
                  v-if="variation.isCurrent && variation.isApproved"
                  class="flex items-center rounded-lg pr-1 pt-px"
                >
                  <Icon
                    name="check"
                    class="text-primary flex self-center"
                    filled
                  />
                </span>

                <span
                  v-if="variation.variationKind === LeaseVariationKind.REMOVED"
                  class="text-gray-550 line-through"
                >
                  <!-- {{ title(variation.leaseField?.fieldType ?? variation.type) }}
                  removed -->
                  -
                </span>

                <span
                  v-if="
                    variation.variationKind ===
                    LeaseVariationKind.STATUS_CHANGED
                  "
                  class="line-through- text-gray-550 rounded-full px-2 py-px text-xs"
                  :class="statusClass(variation)"
                >
                  {{
                    leaseStatusObject[
                      variation.value as keyof typeof leaseStatusObject
                    ].label
                  }}
                </span>

                <span
                  v-else
                  class="line-clamp-4"
                  :class="{
                    'font-semibold text-gray-200':
                      variation.isCurrent && variation.isApproved,
                  }"
                >
                  {{ generateDynamicText(variation.value) || variation.value }}
                </span>
              </span>
            </span>
          </Tooltip>
        </div>

        <!-- Status -->
        <div
          class="-order-2 col-span-4 flex justify-end md:order-none md:col-span-1 md:justify-start"
        >
          <span
            class=""
            :class="{ 'amendment-disabled': !variation.isApproved }"
          >
            <span
              v-if="variation.inactiveReason"
              class="inline-flex gap-1 whitespace-nowrap rounded-lg px-2 py-px text-xs text-black"
              :class="{
                'bg-warning': !variation.isApproved,
                'bg-success/70':
                  variation.isApproved &&
                  variation.inactiveReason !== 'superseded',
                'bg-gray-500 text-gray-100':
                  variation.inactiveReason === 'superseded',
              }"
            >
              <Icon name="info" class="self-center text-inherit" filled />

              {{
                variation.inactiveReason === 'futureStartDate'
                  ? 'Upcoming'
                  : formatInactiveReason(variation.inactiveReason)
              }}
            </span>
            <span
              v-else-if="variation.isCurrent && variation.isApproved"
              class="bg-success inline-flex gap-1 rounded-lg px-2 py-px text-xs font-semibold text-black"
            >
              <Icon name="check" class="self-center text-black" filled />
              Active
            </span>
            <span
              v-else-if="variation.isApproved && !variation.isCurrent"
              class="bg-success inline-flex gap-1 rounded-lg px-2 py-px text-xs text-black"
            >
              <Icon name="check" class="self-center text-black" filled />
              Active but not current
            </span>
          </span>
        </div>

        <!-- Actions -->
        <div
          class="col-span-full flex items-end justify-end space-x-2 md:col-span-1 md:items-center"
        >
          <!-- Delete -->
          <div
            class="edit-button flex transition-opacity group-hover/amendment:opacity-100 md:opacity-0"
            @click="$emit('delete', variation)"
          >
            <span
              class="flex h-8 w-8 cursor-pointer items-center justify-center self-center rounded-lg text-red-500 hover:bg-red-500 hover:text-black"
            >
              <Icon name="trash" filled />
            </span>
          </div>
          <!-- Edit -->
          <NuxtLink
            class="edit-button flex transition-opacity group-hover/amendment:opacity-100 md:opacity-0"
            :to="createEditRoute(variation.id)"
          >
            <span
              class="text-primary-500 hover:bg-primary-500 flex h-8 w-8 cursor-pointer items-center justify-center self-center rounded-lg hover:text-black"
            >
              <Icon name="edit" filled />
            </span>
          </NuxtLink>
        </div>
      </div>
    </div>

    <ButtonAddNewSection
      :title="addAmendmentButton"
      size="xs"
      icon="plus"
      hide-line
      @click="$emit('create')"
    />
  </div>
</template>
