import { LeaseVariationInactiveReason } from '@register'

export function localizeVariationInactiveReason(
  inactiveReason: LeaseVariationInactiveReason,
) {
  switch (inactiveReason) {
    case LeaseVariationInactiveReason.DOCUMENT_NOT_PUBLISHED:
      return 'Document not published'
    case LeaseVariationInactiveReason.FUTURE_START_DATE:
      return 'Future start date'
    case LeaseVariationInactiveReason.SUPERSEDED:
      return 'Superseded'
    case LeaseVariationInactiveReason.VARIATION_NOT_APPROVED:
      return 'Variation not approved'
    case LeaseVariationInactiveReason.NOT_INITIALIZED:
      return 'Variation is not initialized'
    default:
      return 'Unknown'
  }
}
