/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum LeaseVariationInactiveReason {
    DOCUMENT_NOT_PUBLISHED = 'documentNotPublished',
    VARIATION_NOT_APPROVED = 'variationNotApproved',
    SUPERSEDED = 'superseded',
    FUTURE_START_DATE = 'futureStartDate',
    NOT_INITIALIZED = 'notInitialized',
}
